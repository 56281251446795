<template>
  <footer class="x-content w-full mt-auto py-2">
    <div class="text-xs flex justify-between items-center flex-wrap -mx-2">
      <div class="flex py-2">
        <span class="mx-2 whitespace-no-wrap">
          © {{ new Date().getFullYear() }}
          <span class="hidden sm:inline">Heresy</span>
        </span>
      </div>

      <div class="flex py-2">
        <a
          v-for="platform of settings.social"
          :key="platform._key"
          :href="platform.url"
          target="_blank"
          class="mx-2 hover:underline"
        >
          {{ platform.title }}
        </a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useSettings } from '@/stores/settings';

const settings = useSettings();
</script>
